<template>
  <div class="Sidebar--Discount Discount">
    <form class="Discount--Form" v-if="show_discount_form">
      <el-form ref="form"
        @submit.prevent="ApplyDiscount(discountCode)"
      >
        <el-form-item
          :label="AppConfig.translations.discount_form.type_code"
          class="Discount--Input"
        >
          <el-input placeholder v-model="discountCode" class="input-with-select"
            @keydown.enter.native.prevent="ApplyDiscount(discountCode)"
          >
            <el-button
              slot="append"
              type="info"
              @click="ApplyDiscount(discountCode)"
              :loading="CheckoutStatus.DiscountCode.add === 'LOADING'"
            >Aplicar</el-button>
          </el-input>
        </el-form-item>
      </el-form>
    </form>
    <div
      class="Discount__Content"
      v-if="Checkout.discount_codes.length > 0"
    >
      <h6>
        <b>Código aplicado:</b>
      </h6>
      <div v-loading="CheckoutStatus.DiscountCode.delete === 'LOADING'" class="Discount__List">
        <el-tag
          v-for="discountCode in ValidDiscounts" :key="discountCode.id"
          closable
          size="small"
          :disable-transitions="false"
          @close="removeDiscount(discountCode)"
        >{{discountCode.code}}</el-tag>
      </div>
    </div>
    <br>
    <div class="Discount__Content" v-if="Checkout.gift_cards && Checkout.gift_cards.length > 0">
      <h6>
        <b>Cartões-Presente:</b>
      </h6>
      <div v-loading="CheckoutStatus.GiftCard.delete === 'LOADING'" class="Discount__List">
        <el-tag
          v-for="giftCard in Checkout.gift_cards" :key="giftCard.id"
          closable
          size="small"
          :disable-transitions="false"
          @close="removeGiftCard(giftCard.code)"
        >•••• •••• •••• {{giftCard.code}}</el-tag>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'

export default {
  name: 'DiscountForm',
  components: {},
  data () {
    return {
      discountCode: '',
      loading: false
    }
  },
  props: ['products'],
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'CheckoutStatus']),
    ValidDiscounts () {
      return this.Checkout.discount_codes.filter(discount => discount.valid === true && discount.code !== undefined && discount.code !== null)
    },
    show_discount_form () {
      if (
        sessionStorage.getItem('show_discount_form')
      ) {
        // sessionStorage.removeItem('show_discount_form')
        return true
      }
      if (
        this.AppConfig.components.general &&
        this.AppConfig.components.general.discount_form
      ) {
        return !!this.AppConfig.components.general.discount_form.show_discount_form
      }
      return true
    }

  },
  methods: {
    ...mapActions([
      'UpdateCheckoutState',
      'ApplyDiscountCode',
      'ApplyGiftCard',
      'SetActivePaymentMethod',
      'GetPaymentOptions',
      'GetShippingMethods',
      'RemoveGiftCard',
      'RemoveDiscountCode',
      'DeleteDiscount',
      'DeleteAddition'
    ]),
    ...mapFilters(['formatMoney']),
    RemoveError (removeErrorIndex) {
      this.Checkout.errors.splice(removeErrorIndex, 1)
    },
    async ApplyDiscount (discountCode) {
      if (this.hasCode(discountCode)) {
        if (this.isGiftCard(discountCode)) {
          var last4Numbers = discountCode.substring(discountCode.length - 4, discountCode.length)
          var appliedGiftCards = this.Checkout.gift_cards.map((giftCard) => { return giftCard.code })

          // 48de 7bgh 795a h743
          if (appliedGiftCards.includes(last4Numbers)) {
            return false
          }
          await this.ApplyGiftCard({
            checkoutId: this.Checkout.id,
            giftCard: discountCode
          }).then(resp => {
            if (this.Checkout.activeStep === 3) {
              this.clearPaymentState()
              this.GetPaymentOptions(this.Checkout.id)
            }
          })
        } else {
          await this.ApplyDiscountCode({
            checkoutId: this.Checkout.id,
            discountCode
          }).then(resp => {
            if (this.Checkout.activeStep === 2 && this.Checkout.requires_shipping) {
              this.GetShippingMethods(this.Checkout.id)
            }
            if (this.Checkout.activeStep === 3) {
              this.clearPaymentState()
              this.GetPaymentOptions(this.Checkout.id)
            }
          })
        }
      }
    },
    async removeDiscount (discount) {
      await this.RemoveDiscountCode({
        checkoutId: this.Checkout.id,
        discount: discount
      }).then(() => {
        if (this.Checkout.activeStep === 2 && this.Checkout.requires_shipping) {
          this.GetShippingMethods(this.Checkout.id)
        }
        if (this.Checkout.activeStep === 3) {
          this.clearPaymentState()
          this.GetPaymentOptions(this.Checkout.id)
        }
      })
    },
    async removeGiftCard (giftCard) {
      await this.RemoveGiftCard({
        checkoutId: this.Checkout.id,
        giftCard: giftCard
      }).then(() => {
        if (this.Checkout.activeStep === 3) {
          this.clearPaymentState()
          this.GetPaymentOptions(this.Checkout.id)
        }
      })
    },
    clearPaymentState () {
      this.SetActivePaymentMethod(null)
      this.DeleteDiscount({ key: 'id', value: null })
      this.DeleteAddition({ key: 'id', value: null })
      this.UpdateCheckoutState({
        totals: {
          ...this.Checkout.totals,
          addition: null
        }
      })
    },
    isGiftCard (code) {
      return this.hasWhiteSpace(code)
    },
    hasWhiteSpace (code) {
      return code.indexOf(' ') >= 0
    },
    hasCode (code) {
      if (!code) return false
      return code.length > 1
    }
  }
}
</script>
